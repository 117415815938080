.DemoHeader {
  height: 64px;
  background-color: #F6F9FA;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.DemoHeader-container {
  height: 100%;
}
.DemoHeader-logo {
  width: 171px;
  height: 41px;
  padding-top: 13px;
}
.DemoHeader-link {
  text-decoration: none;
  color: #535362 !important;
  padding: 0 20px;
  display: inline-block;
  transition: 250ms background-color;
  line-height: 64px;
}
.DemoHeader-link:hover {
  background-color: #F6F9FA;
}
.DemoHeader-github {
  margin-top: 17px;
  margin-left: 10px;
}
.DemoHeader-spacer {
  padding-top: 64px;
}
