@import "http://fonts.googleapis.com/css?family=Montserrat:300,400,700";
.ng-hide:not(.ng-hide-animate) {
  /* this is just another form of hiding an element */
  display: block!important;
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.rwd-table {
  margin: 1em 0;
  min-width: 300px;
}
.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.rwd-table th {
  display: none;
}
.rwd-table td {
  display: block;
}
.rwd-table td:first-child {
  padding-top: .5em;
}
.rwd-table td:last-child {
  padding-bottom: .5em;
}
.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 0.5em;
  display: inline-block;
}
.rwd-table tbody tr td {
  color: black;
}
@media (min-width: 480px) {
  .rwd-table td:before {
    display: none;
  }
}
.rwd-table th,
.rwd-table td {
  text-align: left;
}
@media (min-width: 480px) {
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
}
.rwd-table thead {
  background: #1565c0;
  border-color: #46637f;
}
h1 {
  font-weight: normal;
  letter-spacing: -1px;
  color: #000;
}
.rwd-table {
  background: #white;
  color: #black;
  margin-top: 0px !important;
  overflow: hidden;
}
.rwd-table tr {
  border-color: #46637f;
  color: white;
}
.rwd-table th,
.rwd-table td {
  margin: .5em 1em;
}
@media (min-width: 480px) {
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}
.rwd-table th,
.rwd-table td:before {
  color: #fff;
}
