.LoginForm-inputContainer {
  margin-top: 10px;
  margin-bottom: 0px;
  width: 80%;
  min-width: 300px;
}
.LoginForm-submit {
  margin-bottom: 40px;
  margin-left: 0;
  width: 100%;
  padding: 5px 0;
}
